import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react';
import { NextChakraButton } from 'components/wrappers';
import { Menu, menuIcons } from 'configs/menus';
import { MenuDrawer } from './MenuDrawer';

import * as icons from 'react-icons/fc';
import useTranslation from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import { getPathInfo } from 'utls';

export const MenuItem: React.FC<
  Menu & { fontSize: ButtonProps['fontSize'] }
> = ({
  url,
  title,
  child_items,
  icon,
  fontSize = 'lg',
  children,
  isChild,
  showSubMenu,
  setShowSubMenu
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const hasChildItems = Boolean(child_items);
  const href = url ?? '/';
  const openDrawer = useBreakpointValue({
    base: false,
    // md: false,
    lg: true,
    xl: false
  });

  const { T, locale } = useTranslation();

  const { url: langUrl } = getPathInfo(href);

  const nextLocale = locale === 'en' ? 'ar' : 'en';

  const { pathname, push } = useRouter();
  if (langUrl.includes('/en') || langUrl === '/')
    return (
      <Button
        onClick={() => {
          if (langUrl.includes('/en')) {
            push(pathname, '/en', { locale: nextLocale });
          } else {
            push(pathname, '/', { locale: nextLocale });
          }
        }}
        display={'inline-flex'}
        textAlign={{ base: 'center', xl: 'start' }}
        justifyContent={{ base: 'center', xl: 'flex-start' }}
        width={'fit-content'}
        borderRadius={'3xl'}
        variant='unstyled'
        fontWeight={'normal'}
        flexShrink={0}
        color={'dark.600'}
        bgColor={'transparent'}
        fontSize={'lg'}
        pr={{ base: 4, lg: 0, xl: 4 }}
        pl={{ base: 4, lg: 0, xl: 4 }}
        mb='2'
        _hover={{
          bgColor: 'light.500'
        }}
      >
        <Text>
          {icon}{' '}
          <Text
            as='span'
            display={{
              md: 'inline',
              lg: 'none',
              xl: 'inline'
            }}
          >
            {title}
          </Text>
        </Text>
      </Button>
    );

  return (
    <>
      <HStack>
        <NextChakraButton
          onClick={() => {
            openDrawer && hasChildItems
              ? onToggle()
              : hasChildItems
              ? setShowSubMenu(showSubMenu)
              : '';
          }}
          hasChildItems={hasChildItems}
          href={href}
          // leftIcon={icon && <Icon as={menuIcons?.[icon] ?? icons[icon]} />}
          display={'inline-flex'}
          textAlign={{ base: 'center', xl: 'start' }}
          justifyContent={{ base: 'center', xl: 'flex-start' }}
          width={'fit-content'}
          borderRadius={'3xl'}
          variant='unstyled'
          fontWeight={'normal'}
          flexShrink={0}
          color={'dark.600'}
          bgColor={'transparent'}
          fontSize={fontSize}
          pr={{ base: 4, lg: 0, xl: 4 }}
          pl={{ base: 4, lg: 0, xl: 4 }}
          mb='2'
          _hover={{
            bgColor: 'light.500'
          }}
          sx={{
            '&.active': {
              color: 'black',
              bgColor: 'light.500'
            },
            span: {
              marginInlineEnd: { lg: isChild ? '0.5rem' : '0rem', xl: '0.5rem' }
            }
          }}
        >
          <Text>
            {icon}{' '}
            <Text
              as='span'
              display={{
                base: 'inline',
                lg: isChild ? 'initial' : 'none',
                xl: 'inline'
              }}
            >
              {title}
            </Text>
          </Text>
        </NextChakraButton>
      </HStack>

      {isOpen && (
        <MenuDrawer
          isOpen={isOpen}
          onToggle={onToggle}
          items={child_items}
          title={title}
        />
      )}
    </>
  );
};
