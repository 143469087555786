import { Box, Icon, Text, VStack } from '@chakra-ui/react';
import LoginModal from 'components/shared/LoginModal';
import { useAuth } from 'context';
import { useMenu } from 'hooks';
import { useSession } from 'next-auth/react';

import { useEffect, useState } from 'react';
import { AuthBtn } from './AuthBtn';
import { MenuItem } from './MenuItem';
import { useRouter } from 'next/router';
import { NextChakraButton } from 'components/wrappers';

const MainMenu: React.FC = ({ translation, currentLang }: any) => {
  const headerMenu = useMenu('header');
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [parentId, setParenId] = useState('');
  const { token, isLoggedIn }: any = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const { data: session, status } = useSession();

  const { push, pathname, asPath, locale, reload } = useRouter();

  const nextLocale = locale === 'en' ? 'ar' : 'en';

  const changeLang = () => {
    push(pathname, translation?.link, { locale: translation?.lang });
    // force a reload for it to work correctly.
    // reload();
  };
  console.log(pathname, 'pathnamepathname');
  console.log(asPath, 'asPathasPath');
  console.log(nextLocale, 'nextLocalenextLocale');

  console.log(translation, 'translationtranslation');

  useEffect(() => {
    console.log(window.location.origin, 'orrrr');
  }, []);

  return (
    <>
      <Box
        pos={'sticky'}
        mt='4'
        justifyContent={{ base: 'initial', lg: 'center' }}
        display={{ base: 'inline-flex', lg: 'flex', xl: 'flex' }}
        width={{ base: '65%', lg: '100%', xl: '100%' }}
        as='nav'
        // minH={'50vh'}
        transform='translateX(0%)'
        minH='100vh'
      >
        <Box overflow={{ base: 'initial', md: 'hidden' }} position='relative'>
          {headerMenu &&
            headerMenu?.map((item, index) => {
              const isLastItem = index === headerMenu.length - 1;
              return (
                <Box key={index}>
                  <Box
                    mb='2'
                    pos='relative'
                    onClick={() => {
                      setParenId(item.id);
                    }}
                    transform={
                      showSubMenu ? 'translateX(110%)' : 'translateX(0%)'
                    }
                    opacity={showSubMenu ? '0' : '1'}
                    visibility={showSubMenu ? 'hidden' : 'visible'}
                    transition='transform 0.4s, opacity 0.4s, visibility 0.4s'
                    overflow={'hidden'}
                  >
                    {/* {index === 0 && translation && (
                      <NextChakraButton
                        onClick={changeLang}
                        // leftIcon={icon && <Icon as={menuIcons?.[icon] ?? icons[icon]} />}
                        display={'inline-flex'}
                        textAlign={{ base: 'center', xl: 'start' }}
                        justifyContent={{ base: 'center', xl: 'flex-start' }}
                        width={'fit-content'}
                        borderRadius={'3xl'}
                        variant='unstyled'
                        fontWeight={'normal'}
                        flexShrink={0}
                        color={'dark.600'}
                        bgColor={'transparent'}
                        fontSize={'lg'}
                        pr={{ base: 4, lg: 0, xl: 4 }}
                        pl={{ base: 4, lg: 0, xl: 4 }}
                        mb='2'
                        _hover={{
                          bgColor: 'light.500'
                        }}
                      >
                        <Text>
                          {'🌐 '}
                          <Text
                            as='span'
                            display={{
                              md: 'inline',
                              lg: 'none',
                              xl: 'inline'
                            }}
                          >
                            {locale === 'ar' ? 'الأنجليزية' : 'العربية'}
                          </Text>
                        </Text>
                      </NextChakraButton>
                    )} */}
                    <MenuItem
                      {...item}
                      key={item.id}
                      showSubMenu={true}
                      setShowSubMenu={setShowSubMenu}
                      isChild={false}
                    />

                    {index === 0 && session && (
                      <MenuItem
                        {...item}
                        url='/bookmarks'
                        title='مجلتي'
                        icon={' 📚'}
                        key={item.id}
                        showSubMenu={true}
                        setShowSubMenu={setShowSubMenu}
                        isChild={false}
                      />
                    )}
                    {isLastItem && (
                      <Box display={'flex'} justifyContent='center' mt='2'>
                        <AuthBtn />
                      </Box>
                    )}
                  </Box>
                  <Box
                    className='subMenu'
                    pos={'absolute'}
                    top='0'
                    width={'100%'}
                    transform={
                      showSubMenu ? 'translateX(0%)' : 'translateX(-110%)'
                    }
                    opacity={showSubMenu ? '1' : '0'}
                    visibility={showSubMenu ? 'visible' : 'hidden'}
                    transition='transform 0.4s, opacity 0.4s, visibility 0.4s'
                  >
                    {parentId === item.id && (
                      <MenuItem
                        {...item}
                        showSubMenu={false}
                        setShowSubMenu={setShowSubMenu}
                        isChild={false}
                      />
                    )}
                    <VStack
                      mt='4'
                      align={'stretch'}
                      ml={4}
                      borderInlineStartWidth={2}
                      borderInlineStartColor='light.500'
                      overflowY={{ base: 'none', lg: 'scroll', xl: 'scroll' }}
                      overflowX='hidden'
                      maxH={{
                        base: 'none',
                        md: 'none',
                        lg: '65vh',
                        xl: '65vh'
                      }}
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: 1
                        },
                        '&::-webkit-scrollbar-track': {
                          width: 1
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: 'var(--arageek-colors-dark-300)',
                          borderRadius: 'md'
                        }
                      }}
                    >
                      {Array.isArray(item?.child_items) &&
                        parentId === item.id &&
                        item?.child_items?.map((child, index) => {
                          return (
                            <MenuItem
                              key={child.id}
                              {...child}
                              fontSize='md'
                              isChild={true}
                            />
                          );
                        })}
                      {Array.isArray(item?.child_items) &&
                        !showSubMenu &&
                        item?.child_items?.map((child, index) => {
                          return (
                            <Box display={'none'} key={child.id}>
                              <MenuItem
                                {...child}
                                fontSize='md'
                                isChild={true}
                              />
                            </Box>
                          );
                        })}
                    </VStack>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </>
  );
};

export default MainMenu;
